body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*
Links are somehow not highlighted by default because the react theming overrides that
These stylings fix this issue
*/
a {
  color: #0045e7 !important;
}

a:hover {
  text-decoration: underline !important;
  color: #0043a8 !important;
}
/* Navbar links need to be excluded from the rule so this class is added to the link component */
a.navbar-link {
  text-decoration: none !important;
  color: inherit !important;
}

a.navbar-link:hover {
  text-decoration: none !important;
  color: inherit !important;
}
